<template>
  <div class="v-stack h-stretch gap-3">
    <div class="h-stack h-space-between">
      <a class="heading-title-2">Balance</a>
      <button class="add" @click="$router.push('/finance/expenses/add/gear')">
        Add Expense
      </button>
    </div>
    <div class="card light mn">
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <div class="v-stack h-stretch v-start gap-3 card py-5">
            <div class="pane-horizontal gap-3">
              <div class="text-right">Income:</div>
              <div class="text-left">{{ format.currency(totalIncome) }} kč</div>
            </div>
            <div class="pane-horizontal gap-3">
              <div class="text-right">Expenses:</div>
              <div class="text-left">{{ format.currency(totalCost) }} kč</div>
            </div>
            <div class="separator"></div>
            <div class="pane-horizontal gap-3" style="font-weight: bold">
              <div class="text-right">Balance:</div>
              <div class="text-left">{{ format.currency(balance) }} kč</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="heading-title-2">Expenses</a>
    <div
      class="card light link mn"
      @click="$router.push('/lifefinance/expenses')"
    >
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <div class="pane-horizontal gap-3">
            <div class="v-stack h-stretch v-start gap-3 card py-5">
              <a>Additional Expenses</a>
              <div class="pane-horizontal gap-3">
                <div class="text-right">One Time Expenses:</div>
                <div class="text-left">
                  {{ format.currency(oneTimeExpenseCost) }} kč
                </div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Reccuring Expenses:</div>
                <div class="text-left">
                  {{ format.currency(reccuringExpenseCost) }} kč
                </div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Gear:</div>
                <div class="text-left">
                  {{ format.currency(totalGearCost) }} kč
                </div>
              </div>
            </div>
            <div class="v-stack h-stretch v-start gap-3 card py-5">
              <a>Workforce</a>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Crew:</div>
                <div class="text-left">{{ format.currency(crewCost) }} kč</div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Freelancers:</div>
                <div class="text-left">Not yet implemented</div>
              </div>
            </div>
          </div>
          <div class="v-stack h-stretch v-start gap-3 card py-5">
            <a>Sum</a>
            <div class="pane-horizontal gap-3">
              <div class="text-right">Expenses:</div>
              <div class="text-left">
                {{ format.currency(expensesCost) }} kč
              </div>
            </div>
            <div class="pane-horizontal gap-3">
              <div class="text-right">Workforce:</div>
              <div class="text-left">
                {{ format.currency(totalWorkForceCost) }} kč
              </div>
            </div>
            <div class="separator"></div>
            <div class="pane-horizontal gap-3" style="font-weight: bold">
              <div class="text-right">Total:</div>
              <div class="text-left">{{ format.currency(totalCost) }} kč</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="heading-title-2">Income</a>
    <div
      class="card light link mn"
      @click="$router.push('/lifefinance/income')"
    >
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <div class="v-stack h-stretch v-start gap-3 card py-5">
            <a>Sum</a>
            <div class="pane-horizontal gap-3">
              <div class="text-right">Projects:</div>
              <div class="text-left">
                {{ format.currency(projectIncome) }} kč
              </div>
            </div>
            <div class="separator"></div>
            <div class="pane-horizontal gap-3" style="font-weight: bold">
              <div class="text-right">Total:</div>
              <div class="text-left">{{ format.currency(totalIncome) }} kč</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils.js";

export default {
  data() {
    return {
      format: utils.format,
    };
  },
  computed: {
    balance() {
      return this.totalIncome - this.totalCost;
    },
  },
  props: {
    oneTimeExpenseCost: {
      type: Number,
      default: 0,
    },
    reccuringExpenseCost: {
      type: Number,
      default: 0,
    },
    totalGearCost: {
      type: Number,
      default: 0,
    },
    crewCost: {
      type: Number,
      default: 0,
    },
    expensesCost: {
      type: Number,
      default: 0,
    },
    totalWorkForceCost: {
      type: Number,
      default: 0,
    },
    totalCost: {
      type: Number,
      default: 0,
    },
    totalIncome: {
      type: Number,
      default: 0,
    },
    projectIncome: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.link:hover {
  cursor: pointer;
  background-color: lightgray;
}
</style>
